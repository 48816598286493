import React, { useState, useEffect, useContext } from 'react';
// import { Line } from "react-chartjs-2";
// eslint-disable-next-line
import Chart from 'chart.js/auto';
import workImg from '../images/work3.png';
import work1Img from '../images/work.png';
import { useTokenInfoStats } from '../hooks/useTokenInfo';
import { formatPrice } from '../hooks/contractHelper';
import { afterDate, api_key, trimAddress, contract, dateFormat, DEFAULT_CHAIN, networkQuery, transactionQuery, formatDateWithZone } from '../hooks/constant';
import moment from 'moment/moment';
// import 'react-circular-progressbar/dist/styles.css';
import { useWeb3React } from "@web3-react/core";
import ReactApexChart from 'react-apexcharts';
import { Context } from "../context/context";
import { ethers } from 'ethers';

export default function Dashboard() {
  const { account } = useWeb3React();
  const [updater] = useState(1);
  const tokenStats = useTokenInfoStats(updater);
  const [dexData, setDexData] = useState([]);
  const [daysLaunch, setDaysLaunch] = useState(0);
  const [ensName, setEnsname] = useState('');
  // const [chartData, setChartData] = useState({

  //   series: [
  //     {
  //       data: [
  //         {
  //           x: new Date(1538857800000),
  //           y: [6593.86, 6604.28, 6586.57, 6600.01]
  //         }]
  //     }
  //   ]
  // });
  const { setProgress } = useContext(Context);

  const variables = {
    "limit": 3,
    "offset": 0,
    "network": networkQuery,
    "token": contract[DEFAULT_CHAIN].GLDN_ADDRESS,
    "after": afterDate,
    "dateFormat": dateFormat
  }


  useEffect(() => {
    async function fetch() {
      const provider = new ethers.providers.JsonRpcProvider("https://mainnet.infura.io/v3/2b819420936a42b08167b135cb2ba003")
      if (account) {
        let ensName = await provider.lookupAddress(account);
        setEnsname(ensName);
      }
    }
    fetch()
  }, [account])


  useEffect(() => {

    setProgress(60);
    try {
      var config = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-API-KEY": api_key
        },
        body: JSON.stringify({
          'query': transactionQuery,
          'variables': variables
        })
      };

      fetch('https://graphql.bitquery.io', config)
        .then(response => response.json())
        .then(response => {
          if (response.data) {
            setDexData(response.data.ethereum.dexTrades);

          }
          else {
            console.log(response);
          }

        })
        .catch(function (error) {
          console.log(error);
        });
      setProgress(80);
    }
    catch (err) {
      console.log(err.message);
    }
    setProgress(100);
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    var dateofvisit = moment('30-09-2022', 'DD-MM-YYYY');
    var today = moment();
    setDaysLaunch(today.diff(dateofvisit, 'days'))
    // eslint-disable-next-line 
  }, []);

  // useEffect(() => {

  //   setProgress(60);
  //   try {
  //     var config = {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         "X-API-KEY": api_key
  //       },
  //       body: JSON.stringify({
  //         'query': chartQuery,
  //         'variables': variables
  //       })
  //     };

  //     fetch('https://graphql.bitquery.io', config)
  //       .then(response => response.json())
  //       .then(response => {
  //         if (response.data) {
  //           let chart = [];


  //           Object.keys(response.data.ethereum.dexTrades).forEach((rowdata) => {
  //             chart[rowdata] = {
  //               x: response.data.ethereum.dexTrades[rowdata].timeInterval.minute,
  //               y: [
  //                 parseFloat(tokenStats.gldn_price / response.data.ethereum.dexTrades[rowdata].open).toFixed(4),
  //                 parseFloat(tokenStats.gldn_price / response.data.ethereum.dexTrades[rowdata].high).toFixed(4),
  //                 parseFloat(tokenStats.gldn_price / response.data.ethereum.dexTrades[rowdata].low).toFixed(4),
  //                 parseFloat(tokenStats.gldn_price / response.data.ethereum.dexTrades[rowdata].close).toFixed(4)
  //               ]
  //             }
  //             return true;
  //           })





  //           setChartData({

  //             series: [
  //               {
  //                 data: chart
  //               }
  //             ]
  //           })
  //         }
  //         else {
  //           console.log(response);
  //         }

  //       })
  //       .catch(function (error) {
  //         console.log(error);
  //       });

  //   }
  //   catch (err) {
  //     console.log(err.message);
  //     // setProgress(100);
  //   }
  //   setProgress(100);
  //   // eslint-disable-next-line
  // }, [tokenStats])

  const Radialbar = {
    className: "forth circle",
    series: [75],
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        gradientToColors: ["#87D4F9"],
        stops: [0, 100],
      },
    },
    options: {
      colors: ["#6259ca"],
      chart: {
        // height: 350,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {},
        },
      },
    },
  };


  return (
    <React.Fragment>
      <div className="jumps-prevent" style={{ "paddingTop": "63.998px" }}></div>
      <div className="main-content side-content pt-0">
        <div className="main-container container-fluid">
          <div className="inner-body">
            <div className="page-header">
              <div>
                <h2 className="main-content-title tx-24 mg-b-5">Welcome To Dashboard</h2>
              </div>
            </div>
            <div className="row row-sm">
              <div className="col-sm-12 col-lg-12 col-xl-8">
                <div className="row row-sm  mt-lg-4">
                  <div className="col-sm-12 col-lg-12 col-xl-12">
                    <div className="card bg-primary custom-card info-card card-box">
                      <div className="card-body p-4">
                        <div className="row align-items-center">
                          <div className="offset-xl-3 offset-sm-6 col-xl-8 col-sm-6 col-12">
                            <h4 className="d-flex  mb-3"> <span className="font-weight-bold address-text text-white" >Welcome , {ensName ? ensName : account ? account : 'connect your wallet'}</span> </h4>
                            <p className="text-white mb-1"><b className="text-white">Note: </b>Make sure you always connected to secured <b className="text-white">app.goldretriever.io</b> for your wallet safety.</p>
                          </div>
                          <img src={workImg} alt="user-img" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row row-sm">
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="card custom-card card-border">
                      <div className="card-body">
                        <div className="card-item">
                          <div className="card-item-icon card-icon">
                            <svg className="text-primary" xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24">
                              <g>
                                <rect height="14" opacity=".3" width="14" x="5" y="5"></rect>
                                <g>
                                  <rect fill="none" height="24" width="24"></rect>
                                  <g>
                                    <path d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M19,19H5V5h14V19z"></path>
                                    <rect height="5" width="2" x="7" y="12"></rect>
                                    <rect height="10" width="2" x="15" y="7"></rect>
                                    <rect height="3" width="2" x="11" y="14"></rect>
                                    <rect height="2" width="2" x="11" y="10"></rect>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </div>
                          <div className="card-item-title mb-2">
                            <label className="main-content-label tx-13 font-weight-bold mb-1">Circulating Supply</label>
                          </div>
                          <div className="card-item-body">
                            <div className="card-item-stat">
                              <h5 className="font-weight-bold">{tokenStats.getCirculatingSupply ? formatPrice(tokenStats.getCirculatingSupply) : 0}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="card custom-card card-border">
                      <div className="card-body">
                        <div className="card-item">
                          <div className="card-item-icon card-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                              <path d="M0 0h24v24H0V0z" fill="none"></path>
                              <path d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33C19.38 15.49 20 13.82 20 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z" opacity=".3"></path>
                              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"></path>
                            </svg>
                          </div>
                          <div className="card-item-title mb-2">
                            <label className="main-content-label tx-13 font-weight-bold mb-1">Market Cap</label>
                          </div>
                          <div className="card-item-body">
                            <div className="card-item-stat">
                              <h5 className="font-weight-bold">${tokenStats.getCirculatingSupply ? formatPrice(tokenStats.getCirculatingSupply * tokenStats.gldn_price) : 0}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="card custom-card card-border">
                      <div className="card-body">
                        <div className="card-item">
                          <div className="card-item-icon card-icon">
                            <svg className="text-primary" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                              <path d="M0 0h24v24H0V0z" fill="none"></path>
                              <path d="M12 4c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm1.23 13.33V19H10.9v-1.69c-1.5-.31-2.77-1.28-2.86-2.97h1.71c.09.92.72 1.64 2.32 1.64 1.71 0 2.1-.86 2.1-1.39 0-.73-.39-1.41-2.34-1.87-2.17-.53-3.66-1.42-3.66-3.21 0-1.51 1.22-2.48 2.72-2.81V5h2.34v1.71c1.63.39 2.44 1.63 2.49 2.97h-1.71c-.04-.97-.56-1.64-1.94-1.64-1.31 0-2.1.59-2.1 1.43 0 .73.57 1.22 2.34 1.67 1.77.46 3.66 1.22 3.66 3.42-.01 1.6-1.21 2.48-2.74 2.77z" opacity=".3"></path>
                              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm.31-8.86c-1.77-.45-2.34-.94-2.34-1.67 0-.84.79-1.43 2.1-1.43 1.38 0 1.9.66 1.94 1.64h1.71c-.05-1.34-.87-2.57-2.49-2.97V5H10.9v1.69c-1.51.32-2.72 1.3-2.72 2.81 0 1.79 1.49 2.69 3.66 3.21 1.95.46 2.34 1.15 2.34 1.87 0 .53-.39 1.39-2.1 1.39-1.6 0-2.23-.72-2.32-1.64H8.04c.1 1.7 1.36 2.66 2.86 2.97V19h2.34v-1.67c1.52-.29 2.72-1.16 2.73-2.77-.01-2.2-1.9-2.96-3.66-3.42z"></path>
                            </svg>
                          </div>
                          <div className="card-item-title  mb-2">
                            <label className="main-content-label tx-13 font-weight-bold mb-1">Current Price(GLDN)</label>
                          </div>
                          <div className="card-item-body">
                            <div className="card-item-stat">
                              <h5 className="font-weight-bold">${tokenStats.gldn_price ? formatPrice(tokenStats.gldn_price) : 0} </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="card custom-card card-border">
                      <div className="card-body">
                        <div className="card-item">
                          <div className="card-item-icon card-icon">
                            <svg className="text-primary" xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24">
                              <g>
                                <rect height="14" opacity=".3" width="14" x="5" y="5"></rect>
                                <g>
                                  <rect fill="none" height="24" width="24"></rect>
                                  <g>
                                    <path d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M19,19H5V5h14V19z"></path>
                                    <rect height="5" width="2" x="7" y="12"></rect>
                                    <rect height="10" width="2" x="15" y="7"></rect>
                                    <rect height="3" width="2" x="11" y="14"></rect>
                                    <rect height="2" width="2" x="11" y="10"></rect>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </div>
                          <div className="card-item-title mb-2">
                            <label className="main-content-label tx-13 font-weight-bold mb-1">Liquidity</label>
                          </div>
                          <div className="card-item-body">
                            <div className="card-item-stat">
                              <h5 className="font-weight-bold">${tokenStats.liquidity ? formatPrice(tokenStats.liquidity) : 0} </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="card custom-card card-border">
                      <div className="card-body">
                        <div className="card-item">
                          <div className="card-item-icon card-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                              <path d="M0 0h24v24H0V0z" fill="none"></path>
                              <path d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33C19.38 15.49 20 13.82 20 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z" opacity=".3"></path>
                              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"></path>
                            </svg>
                          </div>
                          <div className="card-item-title mb-2">
                            <label className="main-content-label tx-13 font-weight-bold mb-1">Reward Distributed</label>

                          </div>
                          <div className="card-item-body">
                            <div className="card-item-stat">
                              <h5 className="font-weight-bold">${tokenStats.totalDistributed ? formatPrice(tokenStats.totalDistributed) : 0} </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="card custom-card card-border">
                      <div className="card-body">
                        <div className="card-item">
                          <div className="card-item-icon card-icon">
                            <svg className="text-primary" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                              <path d="M0 0h24v24H0V0z" fill="none"></path>
                              <path d="M12 4c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm1.23 13.33V19H10.9v-1.69c-1.5-.31-2.77-1.28-2.86-2.97h1.71c.09.92.72 1.64 2.32 1.64 1.71 0 2.1-.86 2.1-1.39 0-.73-.39-1.41-2.34-1.87-2.17-.53-3.66-1.42-3.66-3.21 0-1.51 1.22-2.48 2.72-2.81V5h2.34v1.71c1.63.39 2.44 1.63 2.49 2.97h-1.71c-.04-.97-.56-1.64-1.94-1.64-1.31 0-2.1.59-2.1 1.43 0 .73.57 1.22 2.34 1.67 1.77.46 3.66 1.22 3.66 3.42-.01 1.6-1.21 2.48-2.74 2.77z" opacity=".3"></path>
                              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm.31-8.86c-1.77-.45-2.34-.94-2.34-1.67 0-.84.79-1.43 2.1-1.43 1.38 0 1.9.66 1.94 1.64h1.71c-.05-1.34-.87-2.57-2.49-2.97V5H10.9v1.69c-1.51.32-2.72 1.3-2.72 2.81 0 1.79 1.49 2.69 3.66 3.21 1.95.46 2.34 1.15 2.34 1.87 0 .53-.39 1.39-2.1 1.39-1.6 0-2.23-.72-2.32-1.64H8.04c.1 1.7 1.36 2.66 2.86 2.97V19h2.34v-1.67c1.52-.29 2.72-1.16 2.73-2.77-.01-2.2-1.9-2.96-3.66-3.42z"></path>
                            </svg>
                          </div>
                          <div className="card-item-title  mb-2">
                            <label className="main-content-label tx-13 font-weight-bold mb-1">Current Price(PAXG)</label>
                          </div>
                          <div className="card-item-body">
                            <div className="card-item-stat">
                              <h5 className="font-weight-bold">${tokenStats.paxg_price ? formatPrice(tokenStats.paxg_price) : 0} </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row row-sm">
                  <div className="col-sm-12 col-lg-12 col-xl-12">
                    <iframe className="dexscreen-card" title="GLDN Chart" style={{ "height": "519px" }} src="https://dexscreener.com/ethereum/0x19a7c579e714e43c57997318ff0ba46a6d6891e4?embed=1&amp;theme=dark&amp;info=0"></iframe>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-12 col-xl-4 mt-xl-4">
                <div className="card custom-card card-dashboard-calendar pb-0">
                  <label className="main-content-label mb-2 pt-1">Recent Transactions</label>
                  <table className="table table-hover m-b-0 transcations mt-2">
                    <tbody>
                      {dexData.map((rowdata, index) => {
                        return (
                          <tr key={index}>
                            {/* <td className="wd-5p">
                              <div className="main-img-user avatar-md">
                                <img alt="avatar" className="rounded-circle me-3" src="../assets/img/users/5.jpg" />
                              </div>
                            </td> */}
                            <td>
                              <div className="d-flex align-middle ms-3">
                                <div className="d-inline-block">
                                  <h6 className="mb-1">
                                    <a target="_balnk" href={`https://etherscan.io/tx/${rowdata.transaction.hash ? rowdata.transaction.hash : ''}`}>
                                      {trimAddress(rowdata.transaction.hash ? rowdata.transaction.hash : '')}
                                    </a>
                                  </h6>
                                  <p className="mb-0 tx-13 text-muted">
                                    {rowdata.buyCurrency.symbol ? rowdata.buyCurrency.symbol === 'GLDN' ? parseFloat(rowdata.buyAmount).toFixed(3) : parseFloat(rowdata.sellAmount).toFixed(3) : '-'} GLDN
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td className="text-end">
                              <div className="d-inline-block">
                                <h6 className="mb-2 tx-15 font-weight-semibold">
                                  ${rowdata.base_amount_usd && rowdata.buyAmount && rowdata.buyCurrency.symbol ? (rowdata.buyCurrency.symbol === 'GLDN' ? formatPrice((parseFloat(rowdata.base_amount_usd) / parseFloat(rowdata.buyAmount)) * parseFloat(rowdata.buyAmount)) : formatPrice((parseFloat(rowdata.quote_amount_usd) / parseFloat(rowdata.sellAmount)) * parseFloat(rowdata.sellAmount))) : 0}
                                  {rowdata.buyCurrency.symbol ? rowdata.buyCurrency.symbol === 'GLDN' ? <> <i className="fas fa-level-down-alt ms-2 text-danger m-l-10"></i></> : <> <i className="fas fa-level-up-alt ms-2 text-success m-l-10"></i></> : '-'}
                                </h6>
                                <p className="mb-0 tx-11 text-muted">
                                  {rowdata.block.timestamp.time ? formatDateWithZone(rowdata.block.timestamp.time) : ' - '}
                                </p>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="card custom-card">
                  <div className="card-body">
                    <div className="row row-sm">
                      <div className="col-6">
                        <div className="card-item-title">
                          <label className="main-content-label tx-13 font-weight-bold mb-2">Project Launch</label>
                          <span className="d-block tx-12 mb-0 text-muted">Gold Retriever Project launched on</span>
                        </div>
                        <p className="mb-0 tx-24 mt-2">
                          <b className="text-primary">{daysLaunch ? daysLaunch : 0} days</b>
                        </p>
                        <a href="#sec" className="text-muted">30 Tuesday , Aug 2022</a>
                      </div>
                      <div className="col-6">
                        <img src={work1Img} alt="work" className="best-emp" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card custom-card overflow-hidden">
                  <div className="card-header  border-bottom-0 pb-0">
                    <div>
                      <div className="d-md-flex">
                        <label className="main-content-label my-auto pt-2">Buy Tax</label>
                        <div className="ms-auto mt-3 d-flex">
                          <div className="me-3 d-flex text-muted tx-13"><span className="legend bg-success rounded-circle"></span>Buy Tax </div>
                          {/* <div className="d-flex text-muted tx-13"><span className="legend bg-light rounded-circle"></span>Inprogress </div> */}
                        </div>
                      </div>
                      <span className="d-block tx-12 mt-2 mb-0 text-muted"> Buy taxes on trade from dex </span>
                    </div>
                  </div>
                  <div className="card-body card-tax">
                    <div className="row">
                      <div className="col-sm-6 my-auto">
                        <h6 className="mb-3 font-weight-normal">Total Supply</h6>
                        <div className="text-start">
                          <h3 className="font-weight-bold me-3 mb-2 text-primary">10M</h3>
                          <p className="tx-13 my-auto text-muted">On Buy {tokenStats.totalBuyTax ? tokenStats.totalBuyTax : 0}% Tax will charge.</p>
                        </div>
                      </div>
                      <div className="col-md-6 my-auto">
                        <div className="forth circle">
                          {/* <div> */}
                          <ReactApexChart options={Radialbar.options} series={[tokenStats.totalBuyTax ? tokenStats.totalBuyTax : 0]} colors={['#4a87f0']} type="radialBar" height={200} />
                          <div className="chart-circle-value circle-style radialbar">
                            <div className="tx-16 font-weight-bold"></div>{tokenStats.totalBuyTax ? tokenStats.totalBuyTax : 0}%
                          </div>
                          {/* </div> */}

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card custom-card overflow-hidden">
                  <div className="card-header  border-bottom-0 pb-0">
                    <div>
                      <div className="d-md-flex">
                        <label className="main-content-label my-auto pt-2">Sell Tax</label>
                        <div className="ms-auto mt-3 d-flex">
                          <div className="me-3 d-flex text-muted tx-13"><span className="legend bg-danger rounded-circle"></span>Sell Tax </div>
                          {/* <div className="d-flex text-muted tx-13"><span className="legend bg-light rounded-circle"></span>Inprogress </div> */}
                        </div>
                      </div>
                      <span className="d-block tx-12 mt-2 mb-0 text-muted"> Sell taxes on trade from dex </span>
                    </div>
                  </div>
                  <div className="card-body card-tax">
                    <div className="row">
                      <div className="col-sm-6 my-auto">
                        <h6 className="mb-3 font-weight-normal">Total Supply</h6>
                        <div className="text-start">
                          <h3 className="font-weight-bold me-3 mb-2 text-primary">10M</h3>
                          <p className="tx-13 my-auto text-muted">On Sell {tokenStats.totalSellTax ? tokenStats.totalSellTax : 0}% Tax will charge.</p>
                        </div>
                      </div>
                      <div className="col-md-6 my-auto">
                        <div className="forth circle">
                          {/* <div> */}
                          <ReactApexChart options={Radialbar.options} series={[tokenStats.totalSellTax ? tokenStats.totalSellTax : 0]} colors={['#4a87f0']} type="radialBar" height={200} />
                          <div className="chart-circle-value circle-style radialbar">
                            <div className="tx-16 font-weight-bold"></div>{tokenStats.totalSellTax ? tokenStats.totalSellTax : 0}%
                          </div>
                          {/* </div> */}

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
