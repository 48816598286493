import React, { useContext, useMemo } from 'react';
import logoImg from '../images/logo.png';
import logoLight from '../images/logo.png';
import gldnTextImg from '../images/gldn-logotype.png';
import lconLight from '../images/logo.png';
import lconImg from '../images/logo.png';
import { Context } from "../context/context";
import { Link, useParams, useLocation } from 'react-router-dom';
import queryString from "query-string";
import localStorage from 'local-storage'
import Connect from './Connect';


export default function Header() {
    const { setDarkMode, darkMode } = useContext(Context);
    const router = useRouter();

    return (
        <React.Fragment>
            <div className="main-header side-header sticky">
                <div className="main-container container-fluid">
                    <div className="main-header-left">
                        <a className="main-header-menu-icon" href="#sec" id="mainSidebarToggle"><span></span></a>
                        <div className="hor-logo">
                            <Link className="main-logo" to="/">
                                <img src={logoImg} className="header-brand-img desktop-logo" height="37px" width="134px" alt="logo" />
                                <img src={logoLight} className="header-brand-img desktop-logo-dark" alt="logo" height="37px" width="134px" />
                                <img src={gldnTextImg} className="header-brand-img" alt="logo" height="37px"  width="134px" />
                            </Link>
                        </div>
                    </div>
                    <div className="main-header-center">
                        <div className="responsive-logo">
                            {darkMode ? (
                                <Link to="/">
                                    <img src={logoLight} className="mobile-logo" alt="logo" style={{ "height": "30px" }} />
                                    {/* <img src={gldnTextImg} className="header-brand-img" alt="logo" height="37px"  width="134px" /> */}
                                </Link>
                            ) : (
                                <Link to="/">
                                    <img src={logoImg} className="mobile-logo" alt="logo" style={{ "height": "30px" }} />
                                </Link>
                            )}

                            <Link to="/">
                                <img src={logoLight} className="mobile-logo-dark" alt="logo" style={{ "height": "30px" }} />
                                <img src={gldnTextImg} className="header-brand-img" alt="logo" height="37px"  width="134px" />
                            </Link>
                        </div>
                    </div>
                    <div className="main-header-right">
                        <button className="navbar-toggler navresponsive-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent-4" aria-controls="navbarSupportedContent-4" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="fe fe-more-vertical header-icons navbar-toggler-icon"></i>
                        </button>
                        <div className="navbar navbar-expand-lg  nav nav-item  navbar-nav-right responsive-navbar navbar-dark  ">
                            <div className="collapse navbar-collapse" id="navbarSupportedContent-4">
                                <div className="d-flex order-lg-2 ms-auto justify-content-end">
                                    <div className="dropdown d-flex main-header-theme">
                                        <a href="#sec" className="nav-link icon layout-setting" onClick={() => {
                                            setDarkMode(!darkMode);
                                            localStorage.set('dark-mode', !darkMode);
                                        }}>
                                            {darkMode ? (
                                                <span className="dark-layout">
                                                    <i className="fe fe-sun header-icons"></i>
                                                </span>
                                            ) : (
                                                <span className="light-layout">
                                                    <i className="fe fe-moon header-icons"></i>
                                                </span>
                                            )}


                                        </a>
                                    </div>

                                    <div className="dropdown ">
                                        <a href='#sec' className="nav-link icon full-screen-link">
                                            <i className="fe fe-maximize fullscreen-button fullscreen header-icons"></i>
                                            <i className="fe fe-minimize fullscreen-button exit-fullscreen header-icons"></i>
                                        </a>
                                    </div>
                                    <div className='dropdown'>
                                        <Connect />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sticky">
                <div className="main-menu main-sidebar main-sidebar-sticky side-menu is-expanded ps ps--active-y">
                    <div className="main-sidebar-header main-container-1 active">
                        <div className="sidemenu-logo">
                            <Link className="main-logo" to="/">
                                <img src={logoLight} className="header-brand-img desktop-logo" alt="logo" height="37px" width="134px" />
                                <img src={gldnTextImg} className="header-brand-img desktop-logo" alt="logo" height="37px"  width="134px" />
                                <img src={lconLight} className="header-brand-img icon-logo" alt="logo" height="37px"/>
                                <img src={logoImg} className="header-brand-img desktop-logo theme-logo" alt="logo" height="37px" width="134px" />
                                <img src={lconImg} className="header-brand-img icon-logo theme-logo" alt="logo" />
                            </Link>
                        </div>
                        <div className="main-sidebar-body main-body-1">
                            <div className="slide-left disabled active d-none" id="slide-left">
                                <i className="fe fe-chevron-left"></i>
                            </div>
                            <ul className="menu-nav nav">
                                <li className="nav-header active"><span className="nav-label">Dashboard</span></li>
                                <li className={`nav-item  ${router.pathname === "/" ? "active" : ""}`}>
                                    <Link className="nav-link" to="/">
                                        <span className="shape1"></span>
                                        <span className="shape2"></span>
                                        <i className="ti-home sidemenu-icon menu-icon "></i>
                                        <span className="sidemenu-label">Dashboard</span>
                                        <i className="angle fe fe-chevron-right"></i>
                                    </Link>
                                </li>
                                <li className="nav-header active"><span className="nav-label">Account</span></li>
                                <li className={`nav-item  ${router.pathname === "/account" ? "active" : ""}`}>
                                    <Link className="nav-link" to="/account">
                                        <span className="shape1"></span>
                                        <span className="shape2"></span>
                                        <i className="ti-user sidemenu-icon menu-icon "></i>
                                        <span className="sidemenu-label">Account</span>
                                        <i className="angle fe fe-chevron-right"></i>
                                    </Link>
                                </li>
                                <li className="nav-header active"><span className="nav-label">Transcations</span></li>
                                <li className={`nav-item  ${router.pathname === "/transcations" ? "active" : ""}`}>
                                    <Link className="nav-link active" to="/transcations">
                                        <span className="shape1"></span>
                                        <span className="shape2"></span>
                                        <i className="ti-exchange-vertical sidemenu-icon menu-icon "></i>
                                        <span className="sidemenu-label">Transaction</span>
                                        <i className="angle fe fe-chevron-right"></i>
                                    </Link>
                                </li>
                                <li className="nav-header active"><span className="nav-label">Swap</span></li>
                                <li className={`nav-item`}>
                                    <Link className="nav-link" to="/swap">
                                        <span className="shape1"></span>
                                        <span className="shape2"></span>
                                        <i className="si si-layers sidemenu-icon menu-icon "></i>
                                        <span className="sidemenu-label">Swap</span>
                                        <i className="angle fe fe-chevron-right"></i>
                                    </Link>
                                </li>
                                <li className="nav-header active"><span className="nav-label">GLDN EcoSystem</span></li>
                                <li className={`nav-item`}>
                                    <a className="nav-link" target="_blank" rel="noreferrer" href="https://goldretriever.io/prices">
                                        <span className="shape1"></span>
                                        <span className="shape2"></span>
                                        <i className="si si-grid sidemenu-icon menu-icon "></i>
                                        <span className="sidemenu-label">GRDEX Prices</span>
                                        <i className="angle fe fe-chevron-right"></i>
                                    </a>
                                </li>
                                <li className="nav-header active"><span className="nav-label">GLDN Landing Page</span></li>
                                <li className={`nav-item`}>
                                    <a className="nav-link" target="_blank" rel="noreferrer" href="https://goldretriever.io/">
                                        <span className="shape1"></span>
                                        <span className="shape2"></span>
                                        <i className="si si-globe sidemenu-icon menu-icon "></i>
                                        <span className="sidemenu-label">Gold Retriever</span>
                                        <i className="angle fe fe-chevron-right"></i>
                                    </a>
                                </li>
                                <li className="nav-header active"><span className="nav-label">Chart & Docs</span></li>
                                <li className="nav-item">
                                    <a className="nav-link" rel="noreferrer" target="_blank" href="https://goldretriever.io/Gold_Retriever_White_Paper.pdf">
                                        <span className="shape1"></span>
                                        <span className="shape2"></span>
                                        <i className="ti-receipt sidemenu-icon menu-icon"></i>
                                        <span className="sidemenu-label">Whitepaper</span>
                                        <i className="angle fe fe-chevron-right"></i>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" rel="noreferrer" target="_blank" href="https://etherscan.io/address/0xFeeB4D0f5463B1b04351823C246bdB84c4320CC2">
                                        <span className="shape1"></span>
                                        <span className="shape2"></span>
                                        <i className="ti-receipt sidemenu-icon menu-icon"></i>
                                        <span className="sidemenu-label">GLDN Contract</span>
                                        <i className="angle fe fe-chevron-right"></i>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" rel="noreferrer" target="_blank" href="https://www.dextools.io/app/ether/pair-explorer/0x19a7c579e714e43c57997318ff0ba46a6d6891e4">
                                        <span className="shape1"></span>
                                        <span className="shape2"></span>
                                        <i className="ti-receipt sidemenu-icon menu-icon"></i>
                                        <span className="sidemenu-label">GLDN Chart</span>
                                        <i className="angle fe fe-chevron-right"></i>
                                    </a>
                                </li>
                                <li className="nav-header active"><span className="nav-label">Socials</span></li>
                                <li className="nav-item">
                                    <a className="nav-link" rel="noreferrer" target="_blank" href="https://t.me/+WSW7rqfEXrE4YWJh">
                                        <span className="shape1"></span>
                                        <span className="shape2"></span>
                                        <i className="si si-paper-plane sidemenu-icon menu-icon"></i>
                                        <span className="sidemenu-label">Telegram</span>
                                        <i className="angle fe fe-chevron-right"></i>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="https://twitter.com/0xGoldRetriever?s=20&t=dyavEDnjIG1KNcn4bNNuxA">
                                        <span className="shape1"></span>
                                        <span className="shape2"></span>
                                        <i className="si si-social-twitter sidemenu-icon menu-icon"></i>
                                        <span className="sidemenu-label">Twitter</span>
                                        <i className="angle fe fe-chevron-right"></i>
                                    </a>
                                </li>
                                {/* <li className="nav-item">
                                    <a className="nav-link" target="_self" href="hmailto:water@bluarctic.xyz">
                                        <span className="shape1"></span>
                                        <span className="shape2"></span>
                                        <i className="si si-envelope-open sidemenu-icon menu-icon"></i>
                                        <span className="sidemenu-label">Email</span>
                                        <i className="angle fe fe-chevron-right"></i>
                                    </a>
                                </li> */}
                            </ul>
                            <div className="slide-right" id="slide-right">
                                <i className="fe fe-chevron-right"></i>
                            </div>
                        </div>
                    </div>
                    <div className="ps__rail-x" style={{ "left": "0px", "top": "0px" }}>
                        <div className="ps__thumb-x" tabIndex="0" style={{ "left": "0px", "width": "0px" }}></div>
                    </div>
                    <div className="ps__rail-y" style={{ "top": "0px", "height": "269px", "right": "0px" }}>
                        <div className="ps__thumb-y" tabIndex="0" style={{ "top": "0px", "height": "77px" }}>

                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}


export function useRouter() {
    const params = useParams();
    const location = useLocation();

    // Return our custom router object
    // Memoize so that a new object is only returned if something changes
    return useMemo(() => {
        return {
            // For convenience add push(), replace(), pathname at top level
            push: location.push,
            replace: location.replace,
            pathname: location.pathname,
            // Merge params and parsed query string into single "query" object
            // so that they can be used interchangeably.
            // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
            query: {
                ...queryString.parse(location.search), // Convert string to object
                ...params,
            },
            // Include match, location, history objects so we have
            // access to extra React Router functionality if needed.
            location,

        };
    }, [params, location]);
}
