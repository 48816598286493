import React, { useEffect, useState , useContext } from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import { afterDate, api_key, contract, dateFormat, DEFAULT_CHAIN, formatDateWithZone, networkQuery, transactionQuery } from '../hooks/constant';
import { Context } from "../context/context";
import { formatPrice } from '../hooks/contractHelper';


export default function Transcations() {
    const [page, setPage] = useState(0);
    const [dexData, setDexData] = useState([]);
    const [loading, setLoading] = useState(true);
    const { setProgress } = useContext(Context);
  

    const variables = {
        "limit": 10,
        "offset": page,
        "network": networkQuery,
        "token": contract[DEFAULT_CHAIN].GLDN_ADDRESS,
        "after": afterDate,
        "dateFormat": dateFormat
    }


    useEffect(() => {
        setProgress(60)
        try {
            var config = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-API-KEY": api_key
                },
                body: JSON.stringify({
                    'query': transactionQuery,
                    'variables': variables
                })
            };

            fetch('https://graphql.bitquery.io', config)
                .then(response => response.json())
                .then(response => {
                    if (response.data) {
                        console.log(response.data.ethereum.dexTrades)
                        setDexData(response.data.ethereum.dexTrades);
                        setLoading(false);
                    }
                    else {
                        console.log(response);
                    }

                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        catch (err) {
            console.log(err.message);
        }
        setProgress(100)
        // eslint-disable-next-line
    }, [page])

    const override = {
        display: "block",
        position: "absolute",
        top: "60%",
        left: "50%"
    };


    return (
        <React.Fragment>
            <div className="jumps-prevent" style={{ "paddingTop": "63.998px" }}></div>
            <div className="main-content side-content pt-0">
                <div className="main-container container-fluid">
                    <div className="inner-body">

                        <div className="page-header">
                            <div>
                                <h2 className="main-content-title tx-24 mg-b-5">Transaction History</h2>
                            </div>
                        </div>

                        <div className="row row-sm">
                            <div className="col-md-12 col-lg-12 col-xl-12">
                                <div className="card custom-card transcation-crypto">
                                    <div className="card-header border-bottom-0">
                                        <div className="main-content-label">Transaction History</div>
                                        <p className="d-block tx-12 mb-0 mt-2 text-muted">Transaction History it shows you who sent the transaction, how much has been sent, its destination and the fees that were paid for it.</p>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table text-nowrap text-md-nowrap  table-bordered">
                                                <thead>
                                                    <tr role="row">
                                                        <th>Transaction Hash</th>
                                                        <th>Timestamp</th>
                                                        <th>Action</th>
                                                        <th>Price (USD)</th>
                                                        <th>Token Amount</th>
                                                        <th>Total Value (USD)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {!loading && dexData && dexData.map((rowdata, index) => {
                                                        return (
                                                            <tr className="border-bottom odd" key={index}>
                                                                <td>
                                                                    <a target="_balnk" href={`https://etherscan.io/tx/${rowdata.transaction.hash ? rowdata.transaction.hash : ''}`}>
                                                                        {trimAddress(rowdata.transaction.hash ? rowdata.transaction.hash : '')}
                                                                    </a>
                                                                </td>
                                                                <td>{rowdata.block.timestamp.time ? formatDateWithZone(rowdata.block.timestamp.time) : ' - '}</td>
                                                                <td>{rowdata.buyCurrency.symbol ? rowdata.buyCurrency.symbol === 'GLDN' ? <>SELL <i className="fas fa-level-down-alt ms-2 text-danger m-l-10"></i></> : <>BUY <i className="fas fa-level-up-alt ms-2 text-success m-l-10"></i></> : '-'}</td>
                                                                <td>
                                                                    ${rowdata.base_amount_usd && rowdata.buyAmount && rowdata.buyCurrency.symbol ? (rowdata.buyCurrency.symbol === 'GLDN' ? formatPrice(parseFloat(rowdata.base_amount_usd) / parseFloat(rowdata.buyAmount)) : formatPrice(parseFloat(rowdata.quote_amount_usd) / parseFloat(rowdata.sellAmount))) : 0}
                                                                </td>
                                                                <td>
                                                                    {rowdata.buyCurrency.symbol ? rowdata.buyCurrency.symbol === 'GLDN' ? parseFloat(rowdata.buyAmount).toFixed(3) : parseFloat(rowdata.sellAmount).toFixed(3) : '-'} GLDN
                                                                </td>
                                                                <td>
                                                                ${rowdata.base_amount_usd && rowdata.buyAmount && rowdata.buyCurrency.symbol ? (rowdata.buyCurrency.symbol === 'GLDN' ? formatPrice((parseFloat(rowdata.base_amount_usd) / parseFloat(rowdata.buyAmount)) * parseFloat(rowdata.buyAmount)) : formatPrice((parseFloat(rowdata.quote_amount_usd) / parseFloat(rowdata.sellAmount))*parseFloat(rowdata.sellAmount))) : 0}
                                                                    {/* ${tokenStats && tokenStats.barc_price && (rowdata.buyCurrency.symbol  ? rowdata.buyCurrency.symbol === 'GLDN' ? parseFloat(rowdata.buyAmount * tokenStats.barc_price).toFixed(3) : parseFloat(rowdata.sellAmount * tokenStats.barc_price).toFixed(3) : '-')} */}
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                    {
                                                        loading &&
                                                        <tr className="text-center">
                                                            <td colspan="6" height={300} >
                                                                <ClipLoader
                                                                    color="#000"
                                                                    loading={true}
                                                                    cssOverride={override}
                                                                    size={50}
                                                                // className="spinner"
                                                                />
                                                            </td>
                                                        </tr>
                                                    }

                                                </tbody>
                                            </table>
                                            <div className="col-12">
                                                <div className="dataTables_paginate paging_simple_numbers d-flex justify-content-end" id="example1_paginate">
                                                    <ul className="pagination">
                                                        {page > 0 &&
                                                            <li className="paginate_button page-item previous">
                                                                <a href="#sec" className="page-link" onClick={() => {
                                                                    setPage(page > 10 ? page - 10 : 0)
                                                                    setLoading(true);
                                                                }}>Previous</a>
                                                            </li>
                                                        }
                                                        <li className="paginate_button page-item next">
                                                            <a href="#sec" className="page-link" onClick={() => {
                                                                setPage(page + 10)
                                                                setLoading(true);
                                                            }}>Next</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}


export const trimAddress = (addr) => {
    if (addr !== '' && typeof addr !== 'undefined') {
        return `${addr.substring(0, 6)}...${addr.substring(addr.length - 4)}`;
    }
    else {
        return ``;
    }
}
