import { useEffect, useState  } from "react"
import { contract, DEFAULT_CHAIN } from "./constant";
import {  useMulticallContract } from "../hooks/useContracts";
import { toast } from "react-toastify";
import { dividendItf, linkContractItf, pairContractItf, tokenItf } from "../json/interface";

export const useTokenInfoStats = (updater) => {

  const [stats, setStats] = useState({
    buyLiquidityFee: 0,
    buyMarketingFee: 0,
    buyRewardsFee: 0,
    sellLiquidityFee: 0,
    sellMarketingFee: 0,
    sellRewardsFee: 0,
    decimal: 0,
    totalSuppl: 0,
    getCirculatingSupply: 0,
    eth_price: 0,
    gldn_price: 0,
    paxg_price: 0,
    totalDistributed: 0,
    liquidity: 0,
    totalBuyTax: 0,
    totalSellTax: 0
  });

  const mc = useMulticallContract();



  useEffect(() => {
    const fetch = async () => {
      try {
        const [, data] = await mc.aggregate([
          [contract[DEFAULT_CHAIN].GLDN_ADDRESS, tokenItf.encodeFunctionData('buyLiquidityFee')], //0
          [contract[DEFAULT_CHAIN].GLDN_ADDRESS, tokenItf.encodeFunctionData('buyMarketingFee')], //1
          [contract[DEFAULT_CHAIN].GLDN_ADDRESS, tokenItf.encodeFunctionData('buyRewardsFee')], //2
          [contract[DEFAULT_CHAIN].GLDN_ADDRESS, tokenItf.encodeFunctionData('sellLiquidityFee')], //3
          [contract[DEFAULT_CHAIN].GLDN_ADDRESS, tokenItf.encodeFunctionData('sellMarketingFee')], //4
          [contract[DEFAULT_CHAIN].GLDN_ADDRESS, tokenItf.encodeFunctionData('sellRewardsFee')], //5
          [contract[DEFAULT_CHAIN].GLDN_ADDRESS, tokenItf.encodeFunctionData('decimals')], //6
          [contract[DEFAULT_CHAIN].GLDN_ADDRESS, tokenItf.encodeFunctionData('totalSupply')], //7
          [contract[DEFAULT_CHAIN].GLDN_ADDRESS, tokenItf.encodeFunctionData('getCirculatingSupply')], //8
          [contract[DEFAULT_CHAIN].DIVIDEND_ADDRESS, dividendItf.encodeFunctionData('totalDistributed')], //9
          [contract[DEFAULT_CHAIN].WETH_ADDRESS, tokenItf.encodeFunctionData('balanceOf',[contract[DEFAULT_CHAIN].GLDN_LP_ADDRESS])], //10
          [contract[DEFAULT_CHAIN].GLDN_LP_ADDRESS, pairContractItf.encodeFunctionData('getReserves')], //11
          [contract[DEFAULT_CHAIN].ETH_LINK_ADDRESS, linkContractItf.encodeFunctionData('latestRoundData')], //12 
          [contract[DEFAULT_CHAIN].PAXG_LINK_ADDRESS, linkContractItf.encodeFunctionData('latestRoundData')] //13
          
        ])

        let eth_price = linkContractItf.decodeFunctionResult('latestRoundData', data[12])[1].toString() / Math.pow(10,8)
        let paxg_eth_price = linkContractItf.decodeFunctionResult('latestRoundData', data[13])[1].toString() / Math.pow(10,18)
        let paxg_price = parseFloat(eth_price) * parseFloat(paxg_eth_price) ;
        let gldn_price = parseFloat(eth_price) / parseFloat(pairContractItf.decodeFunctionResult('getReserves', data[11])[1].toString() / pairContractItf.decodeFunctionResult('getReserves', data[11])[0].toString()).toFixed(5);

        setStats({
          buyLiquidityFee: tokenItf.decodeFunctionResult('buyLiquidityFee', data[0])[0].toString(),
          buyMarketingFee: tokenItf.decodeFunctionResult('buyMarketingFee', data[1])[0].toString(),
          buyRewardsFee: tokenItf.decodeFunctionResult('buyRewardsFee', data[2])[0].toString(),
          sellLiquidityFee: tokenItf.decodeFunctionResult('sellLiquidityFee', data[3])[0].toString(),
          sellMarketingFee: tokenItf.decodeFunctionResult('sellMarketingFee', data[4])[0].toString(),
          sellRewardsFee: tokenItf.decodeFunctionResult('sellRewardsFee', data[5])[0].toString(),
          decimal: tokenItf.decodeFunctionResult('decimals', data[6])[0].toString(),
          totalSuppl: tokenItf.decodeFunctionResult('totalSupply', data[7])[0].toString() / Math.pow(10, tokenItf.decodeFunctionResult('decimals', data[6])[0].toString()),
          getCirculatingSupply: tokenItf.decodeFunctionResult('getCirculatingSupply', data[8])[0].toString() / Math.pow(10, tokenItf.decodeFunctionResult('decimals', data[6])[0].toString()),
          eth_price: eth_price,
          gldn_price: gldn_price,
          paxg_price: paxg_price,
          totalDistributed: ( dividendItf.decodeFunctionResult('totalDistributed', data[9])[0].toString() / Math.pow(10, 18)) * (paxg_price),
          liquidity: parseFloat(tokenItf.decodeFunctionResult('balanceOf', data[10])[0].toString() / Math.pow(10, 18)) * 2 * eth_price,
          totalBuyTax: (tokenItf.decodeFunctionResult('buyLiquidityFee', data[0])[0].toString() / 10) + (tokenItf.decodeFunctionResult('buyMarketingFee', data[1])[0].toString() / 10) + (tokenItf.decodeFunctionResult('buyRewardsFee', data[2])[0].toString() / 10),
          totalSellTax: (tokenItf.decodeFunctionResult('sellLiquidityFee', data[3])[0].toString() / 10) + (tokenItf.decodeFunctionResult('sellMarketingFee', data[4])[0].toString() / 10) + (tokenItf.decodeFunctionResult('sellRewardsFee', data[5])[0].toString() / 10)
        })
        
      }
      catch (err) {
        console.log(err.message);
        toast.error(err.reason);
        

      }
      
    }

    if (mc) {
      fetch();
    }
    else {
      setStats({
        buyGldnFee: 0,
        buyGrowthFe: 0,
        buyLiquidityFe: 0,
        sellGldnFe: 0,
        sellGrowthFe: 0,
        sellLiquidityFe: 0,
        decimal: 0,
        totalSuppl: 0,
        getCirculatingSupply: 0,
        eth_price: 0,
        gldn_price: 0,
        barc_price: 0,
        totalDistributed: 0,
        liquidity: 0,
        totalBuyTax: 0,
        totalSellTax: 0
      })
    }
    // eslint-disable-next-line
  }, [updater]);

  return stats;
}
