import { useMemo } from "react";
import { contract, DEFAULT_CHAIN } from '../hooks/constant';
import { supportNetwork } from "./network";
import multiCallAbi from '../json/multicall.json';
import { getWeb3 } from "./connectors";
import { ethers } from "ethers";







export const multiCallContractConnect = () => {
  let multicallAddress = contract[DEFAULT_CHAIN].MULTICALL_ADDRESS;
  let web3 = getWeb3();
  return new web3.eth.Contract(multiCallAbi, multicallAddress);
}

export const useMulticallContract = () => {
  return useMemo(() => {
    let multicallAddress = contract[DEFAULT_CHAIN].MULTICALL_ADDRESS;
    const signerOrProvider = new ethers.providers.JsonRpcProvider(supportNetwork[DEFAULT_CHAIN].rpc);
    return new ethers.Contract(multicallAddress, multiCallAbi, signerOrProvider)
    // eslint-disable-next-line
  }, []);
}

