import { useEffect, useState } from "react"
import { contract, DEFAULT_CHAIN } from "./constant";
import { useMulticallContract } from "../hooks/useContracts";
import { toast } from "react-toastify";
import { useWeb3React } from "@web3-react/core";
import { dividendItf, pairContractItf, tokenItf } from "../json/interface";


export const useAccountStats = (updater) => {
    let { account , chainId } = useWeb3React();
   
    const [stats, setStats] = useState({
        eth_price: 0,
        gldn_price: 0,
        paxg_price: 0,
        token_balance: 0,
        reward_balance: 0,
        holdingWorth: 0,
        rewardWorth: 0,
        realised: 0

    });

    const mc = useMulticallContract();


    useEffect(() => {
        const fetch = async () => {
            try {
                
                const [,data] = await mc.aggregate([
                    [contract[DEFAULT_CHAIN].GLDN_LP_ADDRESS, pairContractItf.encodeFunctionData('getReserves')], //0
                    [contract[DEFAULT_CHAIN].PAXG_LP_ADDRESS, pairContractItf.encodeFunctionData('getReserves')], //1 
                    [contract[DEFAULT_CHAIN].WETH_USD_LP_ADDRESS, pairContractItf.encodeFunctionData('getReserves')], //2 
                    [contract[DEFAULT_CHAIN].GLDN_ADDRESS, tokenItf.encodeFunctionData('balanceOf', [account])], // 3
                    [contract[DEFAULT_CHAIN].DIVIDEND_ADDRESS, dividendItf.encodeFunctionData('getUnpaidEarnings', [account])], // 4
                    [contract[DEFAULT_CHAIN].DIVIDEND_ADDRESS, dividendItf.encodeFunctionData('shares', [account])], // 5
                    [contract[DEFAULT_CHAIN].USDT_USDC_LP_ADDRESS, pairContractItf.encodeFunctionData('getReserves')] //6
                    
                ]);


                let eth_price = (pairContractItf.decodeFunctionResult('getReserves', data[2])[1].toString() / pairContractItf.decodeFunctionResult('getReserves', data[2])[0].toString()) * Math.pow(10, 12) * parseFloat(pairContractItf.decodeFunctionResult('getReserves', data[6])[1].toString() / pairContractItf.decodeFunctionResult('getReserves', data[6])[0].toString());
                let gldn_price = eth_price / (pairContractItf.decodeFunctionResult('getReserves', data[0])[1].toString() / pairContractItf.decodeFunctionResult('getReserves', data[0])[0].toString());
                let paxg_price = eth_price / (pairContractItf.decodeFunctionResult('getReserves', data[1])[0].toString() / pairContractItf.decodeFunctionResult('getReserves', data[1])[1].toString());

               

               setStats({
                    token_balance: tokenItf.decodeFunctionResult('balanceOf', data[3])[0].toString() / Math.pow(10, 18),
                    reward_balance: dividendItf.decodeFunctionResult('getUnpaidEarnings', data[4])[0].toString() / Math.pow(10, 18),
                    holdingWorth: (tokenItf.decodeFunctionResult('balanceOf', data[3])[0].toString() / Math.pow(10, 18)) * (gldn_price),
                    rewardWorth: (dividendItf.decodeFunctionResult('getUnpaidEarnings', data[4])[0].toString()  / Math.pow(10, 18)) * (paxg_price),
                    realised: (dividendItf.decodeFunctionResult('shares', data[5])[2].toString() / Math.pow(10, 18)),
                    eth_price: eth_price,
                    gldn_price: gldn_price,
                    paxg_price: paxg_price
                
                })
            }
            catch (err) {
                console.log(err.message);
                toast.error(err.reason)

            }
        }

        if (mc && account) {
            fetch();
        }
        else {
            setStats({
                eth_price: 0,
                gldn_price: 0,
                barc_price: 0,
                token_balance: 0,
                reward_balance: 0,
                holdingWorth: 0,
                rewardWorth: 0,
                realised: 0
            })
        }
        // eslint-disable-next-line
    }, [updater, account, chainId]);

    return stats;
}
