import React , {useEffect , useContext} from 'react';
import { Context } from "../context/context";

export default function SwapFrame() {
    const { setProgress , darkMode } = useContext(Context);

    useEffect(() => {
        setProgress(100);
        // eslint-disable-next-line
    }, [])
    

    return (
        <React.Fragment>
            <div className="jumps-prevent" style={{ "paddingTop": "63.998px" }}></div>
            <div className="main-content side-content pt-0">
                <iframe title='uniswap Frame' src={`https://app.uniswap.org/#/swap?theme=${darkMode ? 'dark' : 'light'}&amp;outputCurrency=0xfeeb4d0f5463b1b04351823c246bdb84c4320cc2&amp;chain=mainnet`} height="600px" width="100%"></iframe>
            </div>
        </React.Fragment>
    )
}
